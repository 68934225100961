.overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.galleryModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 10px;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1001;
}

.navButton {
    background: transparent;
    border: none;
    font-size: 5rem;
    cursor: pointer;
    padding: 1rem;
    user-select: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    color: white;
}

.navButton:hover {
    background: grey;
    font-size: 6rem;
}

.navButton.left {
    left: 10px;
}

.navButton.right {
    right: 10px;
}