.iframeContainer {
    margin: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    position: relative;
    height: 0;
}

.iframeContainer iframe {
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media (min-width: 1024px) {
    .iframe-container {
        padding-top: 50%;
        /* 16:9 Aspect Ratio */
    }
}

@media (min-width: 1200px) {
    .iframe-container {
        padding-top: 0;
        height: 400px;
    }

    .iframe-container iframe {
        width: 800px;
        height: 400px;
    }
}