.parallaxContainer {
    position: relative;
    overflow: hidden;
    height: 500px;
    /* Adjust the height as needed */
}

.parallaxBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    z-index: -1;
}

.parallaxContent {
    position: relative;
    z-index: 1;
    padding: 20px;
    color: white;
    text-align: center;
}